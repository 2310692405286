export const soon = {
  title: "Come Back Later...",
  body: `<br />
    <h1 style="font-size: 2em;">اهلا, مالك زربانة ؟</h1>
    <br /><br /><br />
    <p style="font-size: 1.5em;">
         يالاه عارفك غادي تسطاي وتعرفي اشنو هنا, حيث نتي فضولية ولكن واااالو, هاذ اللعيبة ما غتحل حتى ليلة عيد ميلادك مع 12 ديال الليل
    </p>`,
};

export const late = {
  title: "See you next time...",
  body: `<br />
    <h1>The party was over</h1>
    <br /><br /><br />
    <p>
        Yes, my gift for you is kinda simple, cheap, and weird ? &#128534<br>
        B-but. It's only for you. &#128150
    </p>
`,
};
